import React, { useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { Container } from "@material-ui/core"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import LoginForm from "../../components/login-form"
import PageTitle from "../../components/page-title"
import permIdentity from "../../images/perm-identity.svg"
import { breakpoints } from "../../components/variables"
import { isLoggedIn } from "../../components/authentication"

const RequestDemoW = styled.div``
const RequestDemoIW = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 50vh;
  @media screen and (max-width: ${breakpoints.md}) {
    flex-direction: column-reverse;
  }
`

const Login = () => {
  useEffect(() => {
    localStorage.setItem("isNewCustomer", true)
    const checkLoginStatus = async () => {
      if (await isLoggedIn()) navigate("/dashboard/")
    }
    checkLoginStatus()
  })

  return (
    <Layout>
      <SEO title="Login" />
      <PageTitle title="Login" icon={permIdentity} />
      <RequestDemoW>
        <Container>
          <RequestDemoIW>
            <LoginForm />
          </RequestDemoIW>
        </Container>
      </RequestDemoW>
    </Layout>
  )
}

export default Login
