import React from "react"
import styled from "styled-components"
import {
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  Button,
  FormHelperText,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core"
import { PersonRounded, VpnKeyRounded } from "@material-ui/icons"
import { useState } from "react"
import Joi from "@hapi/joi"
import { navigate, Link } from "gatsby"

import { breakpoints } from "./variables"
import { login } from "./authentication"
import clsx from "clsx"

const FormW = styled.div`
  width: 47%;
  @media screen and (max-width: ${breakpoints.md}) {
    width: 100%;
  }
`
const SFormControl = styled(FormControl)`
  margin-top: 2rem;
  &:first-child {
    margin-top: 0;
  }
  & .MuiInput-underline:after {
    border-color: ${props => props.theme.palette.sage.main};
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.palette.sage.main};
  }
  & .Mui-error:after {
    border-color: ${props => props.theme.palette.error.main};
  }
  & .MuiFormHelperText-root {
    color: ${props => props.theme.palette.error.main};
  }
`
const SInputLabel = styled(InputLabel)`
  font-size: 1.2rem;
`
const SInput = styled(Input)`
  font-size: 1.2rem;
`
const SubmitW = styled.div`
  margin-top: 2rem;
  position: relative;
  overflow: hidden;
`
const RememberMeW = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  & .MuiCheckbox-colorPrimary.Mui-checked {
    color: ${props => props.theme.palette.datamap.main};
  }
`
const SButton = styled(Button)`
  border-radius: 2rem;
  font-size: 1.2rem;
  padding: 0.5rem 1.4rem;
  background-color: ${props => props.theme.palette.datamap.main};
  color: ${props => props.theme.palette.primary.main};
  transition: background-color 1s;
  &:hover {
    background-color: ${props => props.theme.palette.sage.main};
  }
`
const LoginErrorMessage = styled.div`
  font-size: 1.2rem;
  padding: 1rem 0;
  text-align: center;
  color: ${props => props.theme.palette.error.main};
`
const formSchema = Joi.object({
  username: Joi.string()
    .min(3)
    .required(),

  password: Joi.string()
    .min(6)
    .required(),
})
const LoginForm = ({ className }) => {
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  const [rememberMe, setRememberMe] = useState(false)
  const [usernameError, setUsernameError] = useState("")
  const [passwordError, setPasswordError] = useState("")
  const [loginError, setLoginError] = useState("")

  const formChangeHandler = event => {
    const { name, value, checked } = event.target
    switch (name) {
      case "username":
        setUsername(value)
        break
      case "password":
        setPassword(value)
        break
      case "rememberMe":
        setRememberMe(checked)
        break
      default:
        break
    }
  }
  const formSubmitHandler = async () => {
    const { error } = formSchema.validate({
      username: username,
      password: password,
    })
    if (error) {
      const { path, message } = error.details[0]

      switch (path[0]) {
        case "username":
          setUsernameError(message)
          setPasswordError("")
          break
        case "password":
          setPasswordError(message)
          setUsernameError("")
          break
        default:
          setUsernameError("")
          setPasswordError("")
          break
      }
    } else {
      setUsernameError("")
      setPasswordError("")
      setLoginError("")
      try {
        await login(username, password)
        navigate("/dashboard")
      } catch (e) {
        setLoginError("Login failed: " + e.message)
      }
    }
  }

  const formEnterPressedHandler = async e => {
    if (e.key === "Enter") await formSubmitHandler()
  }
  return (
    <FormW
      className={clsx("formW", className)}
      onKeyPress={formEnterPressedHandler}
    >
      <SFormControl fullWidth>
        <SInputLabel htmlFor="form-username">Username</SInputLabel>
        <SInput
          id="form-username"
          type="text"
          endAdornment={
            <InputAdornment position="end">
              <PersonRounded />
            </InputAdornment>
          }
          name="username"
          onChange={formChangeHandler}
          value={username}
          error={usernameError ? true : false}
        />
        <FormHelperText>{usernameError}</FormHelperText>
      </SFormControl>
      <SFormControl fullWidth>
        <SInputLabel htmlFor="form-password">Password</SInputLabel>
        <SInput
          id="form-password"
          type="password"
          endAdornment={
            <InputAdornment position="end">
              <VpnKeyRounded />
            </InputAdornment>
          }
          name="password"
          onChange={formChangeHandler}
          value={password}
          error={passwordError ? true : false}
        />
        <FormHelperText>{passwordError}</FormHelperText>
      </SFormControl>
      <RememberMeW>
        <FormControlLabel
          control={
            <Checkbox
              checked={rememberMe}
              onChange={formChangeHandler}
              name="rememberMe"
              color="primary"
            />
          }
          label="Remember me"
        />
        <Link to="/login/forgot-password">Forgot password?</Link>
      </RememberMeW>
      <SubmitW>
        <SButton onClick={formSubmitHandler}>Login</SButton>
        <LoginErrorMessage>{loginError}</LoginErrorMessage>
      </SubmitW>
    </FormW>
  )
}

export default LoginForm
